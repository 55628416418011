import { _ } from "vue-underscore";
import Vue from "vue";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import CharacterCount from "@tiptap/extension-character-count";
import { Editor, EditorContent } from "@tiptap/vue-2";
import MenuBar from "./MenuBar.vue";
import Image1 from "@tiptap/extension-image";
import History from "@tiptap/extension-history";
export default {
  data: () => ({
    city: {
      id: 0,
      region_code: '',
      region_name: '',
      country_code: '',
      country_name: '',
      code: '',
      name: '',
      url_slug: "",
      name: "",
      priority: "",
      description: "",
      meta_title: "",
      meta_keywords: "",
      meta_description: "",
      head_scripts: "",
      body_scripts: "",
      logoImage: "",
      logo_url: "",
      og_tc_same_to_meta: false,
      faqs: [{ question: '', answer: '' }],
    },
    selectedRegion: {
      region_code: '',
      region_name: ''
    },
    selectedCountry: {
      country_code: '',
      country_name: ''
    },
    selectedCity: {
      code: '',
      name: ''
    },
    companyList: [],
    loading: false,
    company_id: '',
    editor: null,
    isFullScreen: false,
    apiUrl: process.env.VUE_APP_API_URL,
    cityList: [],
    factsList: [],
    showAddFact: false,
    selectedFactIndex: null,
    showAddFaq: false,
    selectedFaqIndex: null,
    faqsList: [],
    webUrl: process.env.VUE_APP_WEB_URL,
    error: "",
    backUrl: "/seo-cities",
    regionList: [],
    countryList: [],
    region_id: "",
    selectedRoom: null,
    selectedCountryOption: null,
    country: []
  }),
  components: {
    EditorContent,
    MenuBar,
  },

  computed: {
    isSameOgTcValue() {
      return this.city.og_tc_same_to_meta ? 1 : 0;
    },
    getRules() {
      return this.city.og_tc_image ? "" : "required";
    },
    getDataTitle() {
      return this.imageName ? this.imageName : "Drag your image here";
    },
    wordCount() {
      if (this.city.post_body.trim() === "") {
        return 0;
      }
      return this.city.post_body.trim().split(/\s+/).length;
    },
    calculateReadingTime1() {
      const wordsPerMinute = 150;
      const minutes = Math.ceil(this.wordCount / wordsPerMinute);
      return minutes;
    },
    getcitycodename() {
      this.code = this.city.code;
      this.name = this.city.name;
      return this.code + '-' + this.name
    },
    getcountrycodename() {
      this.code = this.city.country_code;
      this.name = this.city.country_name;
      return this.code + '-' + this.name
    },
    getRegionCodeName() {
      this.code = this.city.region_code;
      this.name = this.city.region_name;
      return this.code + '-' + this.name
    }
  },
  watch: {
    "city.og_tc_same_to_meta": function (value) {
      if (value) {
        this.city.og_tc_title = this.city.meta_title;
        this.city.og_tc_description = this.city.meta_description;
      } else {
        this.city.og_tc_title = this.city.og_tc_title;
        this.city.og_tc_description = this.city.og_tc_description;
      }
    },
    company_id: {
      handler(value) {
        if (value) {
          this.selectedRegion = {};
          this.selectedCountry = {};
          this.selectedCity = {};
        } else {
          this.selectedRegion = {};
          this.selectedCountry = {};
          this.selectedCity = {};
        }
      }
    },
    selectedRegion: {
      handler(newVal) {
        if (newVal) {
          this.city.region_code = newVal.region_code;
          this.city.region_name = newVal.region_name;
        } else {
          this.selectedRegion = {};
        }
      },
      deep: true,
    },
    selectedCountry: {
      handler(newVal) {
        if (newVal) {
          this.city.country_code = newVal.country_code;
          this.city.country_name = newVal.country_name;
        } else {
          this.selectedCountry = {};
        }
      },
      deep: true,
    },
    selectedCity: {
      handler(newVal) {
        if (newVal) {
          this.city.code = newVal.code;
          this.city.name = newVal.name;
        } else {
          this.selectedCity = {};
        }
      },
      deep: true,
    }
  },
  methods: {
    updateIsSameOgTcValue(event) {
      this.city.og_tc_same_to_meta = event.target.checked;
    },
    cloneItem(index) {
      const newItem = {
        question: '',
        answer: '',
      };
      this.city.faqs.push(newItem);
    },
    removeItem(index) {
      if (this.city.faqs.length > 1) {
        this.city.faqs.splice(index, 1);
      }
    },
    calculateReadingTime(wordCount) {
      const wordsPerMinute = 150;
      const minutes = Math.ceil(wordCount / wordsPerMinute);
      return minutes;
    },
    calculateReadingTimeLabel(wordCount) {
      const minutes = this.calculateReadingTime(wordCount);
      return minutes === 1 ? "minute" : "minutes";
    },
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      const editorElement = document.querySelector(".editor");
      if (editorElement) {
        if (this.isFullScreen) {
          editorElement.style.height = "100vh";
        } else {
          editorElement.style.height = "26rem";
        }
      }
    },
    value(value) {
      const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(value, false);
    },
    initEditor() {
      this.city.body = "";

      this.editor = new Editor({
        content: this.city.body,
        extensions: [
          StarterKit.configure({
            history: false,
          }),
          Link.configure({
            openOnClick: false,
          }),
          CharacterCount,
          BulletList.configure({
            itemTypeName: "listItem",
            keepMarks: true,
            keepAttributes: true,
          }),
          ListItem,
          OrderedList,
          Image1,
          History,
        ],
        onUpdate: () => {
          this.city.body = this.editor.getHTML();
          this.$emit("input", this.city);
        },
      });
    },
    resetForm() {
      this.$refs.observer.reset();
      this.city = {
        id: 0,
        country_id: "",
        region_id: "",
        title: "",
        name: "",
        description: "",
        page_title: "",
        url_slug: "",
        head_section: "",
        body_section: "",
        priority: ""
      };
    },
    selectImage(event) {
      let _vm = this;
      var input = event.target;
      if (input.files && input.files[0]) {
        _vm.city.logoImage = input.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#imagePreview").css(
            "background-image",
            "url(" + e.target.result + ")"
          );
          $("#imagePreview").hide();
          $("#imagePreview").fadeIn(650);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    cityCreateOrUpdate() {
      let _vm = this;
      const com_id = _vm.company_id;
      let fd = new FormData();
      const airline = [];
      if (_vm.city.logo_url) {
        fd.append("logoImage", _vm.city.logo_url);
      }
      _vm.city.og_tc_same_to_meta = _vm.city.og_tc_same_to_meta ? 1 : 0;
      _vm.city.company_id = _vm.company_id;
      _vm.city.body = _vm.editor.getHTML();
      _vm.city.url_slug = _vm.city.url_slug.toLowerCase();

      Object.keys(_vm.city).forEach(key => {
        if (_vm.city[key] !== null) {
          if (key === 'faqs') {
            _vm.city.faqs.forEach((faq, index) => {
              fd.append(`faqs[${index}][question]`, faq.question);
              fd.append(`faqs[${index}][answer]`, faq.answer);
            });
          } else {
            fd.append(key, _vm.city[key]);
          }
        }
      });

      this.axios.post(`/seo/cities/` + _vm.company_id + `/update`, fd).then(function (response) {
        _vm.$router.push(_vm.backUrl);
      });
    },
    getQueryString() {
      let queryString = "?search=";
      return queryString;
    },
    loadCountry() {
      const _vm = this;
      this.axios
        .get(`/seo/regions/` + _vm.company_id + `/list`, _vm.regionList)
        .then(function (response) {
          _vm.regionList = response.data.data;
        })
        .catch(function () { });
    },
    onChangeCountry() {
      const _vm = this;
      const selected = _vm.selectedRegion;
      this.axios
        .get(`seo/countries/` + _vm.company_id + `/list/` + selected.region_code)
        .then(function (response) {
          _vm.countryList = response.data.data;
        })
        .catch(function () { });
    },
    updateUrl(event) {

      if (event.code === 'Space') {
        const inputText = event.target.value.replace(/\s{1,}/g, '-').toLowerCase();
        if (this.city.url_slug.length > 1 && this.city.url_slug[this.city.url_slug.length - 2] === '-') {
          this.city.url_slug = inputText;
        } else {
          this.city.url_slug = inputText;
        }
      }
    },
    onChangeCity() {
      const _vm = this;
      const selected = _vm.selectedCountry;
      debugger
      if (selected) {
        this.axios
          .get(`/seo/cities/` + _vm.company_id + `/distinct-list/` + selected.country_code)
          .then(function (response) {
            _vm.cityList = response.data.data;
          })
          .catch(function () { });
      }
    },
    getDetail() {
      let _vm = this;
      this.axios
        .get("/seo/cities/" + this.$route.params.company_id + `/` + this.$route.params.id)
        .then(function (response) {
          _vm.city = response.data.data;
          _vm.city.body = response.data.data.body;

          _vm.company_id = _vm.city.company_id;
          _vm.value(_vm.city.body);
          if (_vm.city.full_image_url && _vm.city.full_image_url.length > 0) {
            $("#imagePreview").css(
              "background-image",
              "url(" + _vm.city.full_image_url + "?date=" + Date.now() + ")"
            );
            $("#imagePreview").hide();
            $("#imagePreview").fadeIn(650);
          }
          if (_vm.city.full_icon_url && _vm.city.full_icon_url.length > 0) {
            $("#imagePreview1").css(
              "background-image",
              "url(" + _vm.city.full_icon_url + "?date=" + Date.now() + ")"
            );
            $("#imagePreview1").hide();
            $("#imagePreview1").fadeIn(650);
          }
        })
        .catch(function () { });
    },
    getCompany() {
      let _vm = this;
      this.axios
        .get("company-list")
        .then(function (response) {
          _vm.companyList = response.data.data;
          _vm.company_id = _vm.company_id ? _vm.company_id : _vm.companyList[0].id;
          _vm.loadCountry();
        })
        .catch(function () { });
    },
  },
  mounted() {
    if (this.$route.params.id !== undefined && this.$route.params.id !== "") {
      this.getDetail();
    }
    this.getCompany();
    this.initEditor();
  }
};
